<!--
  Usage:
  <pixie-select v-model="attribute">
  </pixie-select>

  (you can also use :value and @input in alternative to v-model)

  Possible opts:

  * name - name of the field
  * options - array with options (objects with value/label keys)
  * opt-groups - array with option groups
  * placeholder - the placeholder for the select
  * disabled - if the select is disabled or not
  * required - if the field should be required
  * default-selected - selects the first item in the options array
  * data-value-missing - custom error message for when the value is missing (and the required)
  * search - if set to true the user has a search input to filter the results

  Check mounted() to understand what is the initial selected value, depending on your props.

  Note: DO NOT REMOVE the selectr-* classes because it'll break the Clockify integration
-->

<template>
  <div
    ref="select"
    class="px-select-container c-selectbox--primary px-select-desktop"
    :class="{
      open: expanded,
      'has-selected': selectedOption,
      'px-select-disabled': disabled === true,
      'px-select-container--top': dropdownPosition.placement === 'top'
    }"
    style="width: 100%;"
  >
    <div class="px-select-selected selectr-selected" :disabled="disabled" tabindex="0" :aria-expanded="expanded" @click="toggleOptions">
      <span v-if="selectedOption" class="px-select-label selectr-label">
        <img v-if="selectedOption.src" class="c-thumb c-thumb--xxs u-mr" :src="selectedOption.src" />
        <span class="u-va-middle">
          {{ selectedOption.label }}
          <span v-if="selectedOption.sublabel" class="u-ts-5 u-pl-tiny u-color-gris">{{ selectedOption.sublabel }}</span>
        </span>
      </span>
      <div class="px-select-placeholder">
        {{ selectedValueOrPlaceholder }}
      </div>
    </div>

    <div ref="options" class="px-select-options-container">
      <div v-show="search" ref="searchcontainer" class="px-select-input-container">
        <input
          ref="search"
          v-model="searchTerm"
          class="px-select-input hf-validated is-valid hf-in-range"
          tagindex="-1"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="true"
          role="textbox"
          type="search"
          placeholder="Search options..."
          tabindex="0"
          aria-invalid="false"
          @keypress.esc.prevent="toggleOptions">
        <button class="px-select-input-clear" type="button"></button>
      </div>

      <div class="px-select-notice"></div>

      <ul class="px-select-options" role="tree" :aria-hidden="!expanded" :aria-expanded="expanded">
        <li
          v-if="allowSelectionReset && value != '' && value != null"
          class="px-select-option"
          role="treeitem"
          @mouseenter="onOptionHover({})"
          @click.stop="reset()"
        >
          <span>
            {{ resetOptionLabel }}
          </span>
        </li>
        <li
          v-for="(option, index) in filteredOptions"
          :key="`${index}_${option.value}`"
          class="px-select-option"
          :class="{ selected: option.selected, active: option === hoveredOption, disabled: option.disabled }"
          role="treeitem"
          :aria-selected="option.selected"
          @mouseenter="onOptionHover(option)"
          @click="selectOption(option)">
          <img v-if="option.src" class="c-thumb c-thumb--xxs u-mr" :src="option.src" />
          <span class="u-va-middle">
            {{ option.label }}
            <span v-if="option.sublabel" class="u-ts-5 u-pl-tiny">{{ option.sublabel }}</span>
          </span>
        </li>

        <ul
          v-for="group in instanceGroups"
          :key="group.text"
          class="px-select-optgroup"
          role="group">
          <li class="px-select-optgroup--label">
            {{ group.text }}
          </li>
          <li
            v-for="option in group.children"
            :key="option.value"
            class="px-select-option"
            :class="{ selected: option.selected, active: option === hoveredOption, disabled: option.disabled }"
            role="treeitem"
            :aria-selected="option.selected"
            @mouseenter="onOptionHover(option)"
            @click="selectOption(option)">
            {{ option.label }}
          </li>
        </ul>
      </ul>
    </div>
    <input
      ref="input"
      class="pixie-select-hfield u-hidden"
      type="text"
      :value="selectedValue"
      :name="name"
      :required="required"
      :data-value-missing="dataValueMissing" />
  </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';
import debounce from 'lodash/debounce';

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    optGroups: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    defaultSelected: {
      type: Boolean,
      default: false
    },
    dataValueMissing: {
      type: String,
      default: 'Please fill out this field.'
    },
    allowSelectionReset: {
      type: Boolean,
      default: false
    },
    resetOptionLabel: {
      type: String,
      default: 'Disable',
    },
    search: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      initialized: false,
      instanceOptions: this.options,
      instanceGroups: this.optGroups,
      expanded: false,
      hoveredOption: null,
      mouseListener: null,
      searchTerm: '',
      resizeObserver: null,
      dropdownPosition: {
        placement: 'bottom',
      }
    };
  },
  computed: {
    selectedValue() {
      if (this.selectedOption) {
        return this.selectedOption.value;
      }

      return undefined;
    },
    selectedOption() {
      if (this.instanceOptions.length === 0 && this.instanceGroups.length === 0) return;

      return this.flattenedOptions.find(option => option.selected);
    },
    flattenedOptions() {
      if (this.instanceOptions.length > 0) {
        return this.instanceOptions;
      }

      if (this.instanceGroups.length === 0) {
        return [];
      }

      let options = [];

      this.instanceGroups.forEach(group => {
        options = [...options, ...group.children];
      });

      return options;
    },
    filteredOptions() {
      if (!this.searchTerm) {
        return this.instanceOptions;
      }

      return this.instanceOptions.filter(option => {
        return option.label.match(new RegExp(this.searchTerm, 'i'));
      });
    },
    selectedValueOrPlaceholder() {
      const selectedOption = this.flattenedOptions.find(option => option.selected);

      if (!selectedOption) {
        return this.placeholder;
      }

      return selectedOption.label;
    }
  },
  watch: {
    expanded(value) {
      // When the dropdown is not expanded we need to reset the hovered option.
      if (!value) {
        this.hoveredOption = null;
      } else {
        this.$nextTick(this.setDropdownPosition);
      }
    },
    options: {
      handler(newOptions) {
        this.instanceOptions = newOptions;
        this.normalizeOptions();
        this.selectDefaultOption();
      },
      deep: true
    },
    optGroups: {
      handler(newGroups) {
        this.instanceGroups = newGroups;
        this.normalizeOptions();
        this.selectDefaultOption();
      },
      deep: true
    },
    value(newValue) {
      this.selectValue(newValue);
    }
  },
  mounted() {
    // To allow options with "id" or "key" instead of "value" and "description" instead of "label",
    // since we have a lot of arrays for options that have that instead of value + label.
    this.normalizeOptions();

    // Select a default option if the selected value is not provided.
    this.selectDefaultOption();

    this.mouseListener = document.addEventListener('mousedown', this.handleMouseDown);
    this.initialized = true;

    // Add event listener for update position of fixed dropdown
    this.resizeObserver = new ResizeObserver(debounce(this.setDropdownPosition, 16));
    this.resizeObserver.observe(this.$refs.select);
    document.addEventListener('scroll', this.setDropdownPosition, true);
  },
  beforeDestroy() {
    this.resizeObserver.unobserve(this.$refs.select);
  },
  destroyed() {
    document.removeEventListener('mousedown', this.handleMouseDown);
    document.removeEventListener('scroll', this.setDropdownPosition, true);
  },
  methods: {
    updateFilters(data) {
      if (this.name === 'status' && data.statuses) {
        this.instanceOptions = data.statuses.map(status => ({
          value: status,
          label: status,
          selected: status === data.selected_status
        }));
        this.addAnyOption();
      } else if (this.name === 'form_type' && data.form_types) {
        this.instanceOptions = data.form_types.map(formType => ({
          value: formType,
          label: formType,
          selected: formType === data.selected_form_type
        }));
        this.addAnyOption();
      }
    },

    addAnyOption() {
      // Ensure "Any" option is always present
      if (!this.instanceOptions.some(option => option.value === '')) {
        this.instanceOptions.unshift({
          value: '',
          label: 'Any',
          selected: this.instanceOptions.every(option => !option.selected)
        });
      }
    },
    reset() {
      this.instanceOptions = this.instanceOptions.map(option => {
        return { ...option, selected: option.value == false };
      });

      this.instanceGroups = this.instanceGroups.map(group => {
        const children = group.children.map(option => {
          return { ...option, selected: option.value == false };
        });

        return { ...group, children };
      });

      this.expanded = false;
      this.$emit('input', '');
      this.reportValidity();
    },
    toggleOptions() {
      if (this.disabled === true || this.$el.disabled == 'disabled') return;

      this.expanded = !this.expanded;

      if (this.search) {
        if (this.expanded) {
          this.$nextTick(() => {
            this.$refs.search.focus();
          });
        } else {
          this.searchTerm = '';
        }
      }
    },
    onOptionHover(option) {
      if (option.disabled === true) return;

      this.hoveredOption = option;
    },
    selectValue(value) {
      const opt = this.flattenedOptions.find(option => option.value === value);
      if (opt) {
        this.selectOption(opt);
      }
    },
    selectDefaultOption() {
      let selected = null;
      let shouldEmit = false;

      // The default selected option is the first option if default-selected prop is set to true.
      // If that's not the case then it will use whatever is in the value prop.
      // If the value prop is not set then uses the option that has selected set to true, if any.
      if (this.flattenedOptions.length > 0 && this.defaultSelected && !this.value) {
        selected = this.flattenedOptions[0];
        // If the user do not pass value, trigger emit to update v-model to default selected option
        shouldEmit = true;
      } else if (this.value) {
        selected = this.flattenedOptions.find(option => this.equalValues(option.value, this.value));
      } else if (this.flattenedOptions.length > 0) {
        selected = this.flattenedOptions.find(option => option.selected);
      }

      this.selectOption(selected, shouldEmit);
    },
    selectOption(selectedOption, shouldEmit = true) {
      if (!selectedOption) return;
      if (selectedOption.disabled === true) return;

      this.instanceOptions = this.instanceOptions.map(option => {
        return { ...option, selected: this.equalValues(option.value, selectedOption.value) };
      });

      this.instanceGroups = this.instanceGroups.map(group => {
        const children = group.children.map(option => {
          return { ...option, selected: this.equalValues(option.value, selectedOption.value) };
        });

        return { ...group, children };
      });

      if (shouldEmit) this.$emit('input', selectedOption.value);
      if (this.expanded) this.toggleOptions();

      this.reportValidity();
    },
    equalValues(value1, value2) {
      return new String(value1).valueOf() === new String(value2).valueOf();
    },
    isSelected(option) {
      return this.value === option.value;
    },
    handleMouseDown(event) {
      const { target } = event;

      if (this.$refs.select.contains(target)) return;
      if (this.$refs.searchcontainer.contains(target)) return;

      if (this.expanded) this.toggleOptions();
    },
    reportValidity() {
      // Report the validity if needed
      this.$nextTick(() => {
        const { input } = this.$refs;

        if (!this.initialized) return;
        if (!input || !input.classList.contains('is-error')) return;

        if (input.closest('form') && input.closest('form').reportValidity) {
         input.closest('form').reportValidity();
        }
      });
    },
    normalizeOptions() {
      this.instanceOptions = this.instanceOptions.map(this.translateOptionKeys);

      this.instanceGroups = this.instanceGroups.map(group => {
        const children = group.children.map(this.translateOptionKeys);

        return { ...group, children };
      });
    },
    translateOptionKeys(option) {
      return {
        src: option.src, // icon for the option
        selected: option.selected || (this.value &&  this.equalValues(this.value, option.value)),
        value: option.value || option.id || option.key || '', /* bugfix: this was set to undefined when it's an empty string */
        label: option.label || option.description,
        sublabel: option.sublabel,
        disabled: option.disabled || false
      };
    },

    setDropdownPosition() {
      const select = this.$refs.select;
      const options = this.$refs.options;
      const selectRect = select.getBoundingClientRect();
      const optionsRect = options.getBoundingClientRect();

      let placement = 'bottom';
      let dropdownTopPosition = selectRect.y + selectRect.height;
      const dropdownLeftPosition = selectRect.left;
      const dropdownHeigh = optionsRect.height;

      if (dropdownTopPosition + dropdownHeigh > window.innerHeight) {
        dropdownTopPosition = selectRect.top - dropdownHeigh;
        placement = 'top';
      }

      this.dropdownPosition.placement = placement;

      options.style.top = `${dropdownTopPosition}px`;
      options.style.left = `${dropdownLeftPosition}px`;
      options.style.width = `${selectRect.width}px`;
    }
  },
};
</script>
